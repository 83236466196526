// .timeline_container{
//     position: relative;
//     margin: 20px;

//     .container__line {
//         /* Border */
//         border-right: 2px solid #aaa;
    
//         /* Positioned at the left */
//         left: 16px;
//         position: absolute;
//         top: 0px;
    
//         /* Take full height */
//         height: 100%;
//     }
    
//     .container__items {
//         /* Reset styles */
//         list-style-type: none;
//         margin: 0px;
//         padding: 0px;
//     }
    
//     .container__item {
//         margin-bottom: 8px;
//     }
    
//     .container__top {
//         /* Center the content horizontally */
//         align-items: center;
//         display: flex;
//         padding-bottom: 15px;
//     }
    
//     .container__circle {
//         /* Rounded border */
//         background-color: rgb(201, 0, 0);
//         // background-color: rgb(170, 170, 170);
//         border-radius: 9999px;
//         z-index: 2;
//         /* Size */
//         height: 32px;
//         width: 32px;
//     }
    
//     .container__title {
//         /* Take available width */
//         flex: 1;
//         padding-left: 5px;
//         font-size: 25px;
//     }
    
//     .container__desc {
//         /* Make it align with the title */
//         margin-left: 48px;
//         ul {
//             li{
//                 list-style-type: disc;
//                 padding-bottom: 10px;
//             }
//         }
//     }
// }



.timeline_container{
    padding-top: 25px;
    // .resume-section{
    //     margin: 10px ;
    // }
    ul {
        li{
            padding-bottom: 10px;
        }
    }
    .block{
        width: 10px;
        background-color: red;
        height: 100px;
        margin: 0 10px;
        display: block;
    }
    .header{
        p{
            margin: 0;
        }
    }
    .circle {
        padding: 13px 20px;
        border-radius: 50%;
        background-color: #ED8D8D;
        color: #fff;
        max-height: 50px;
        z-index: 2;
      }
      
      .how-it-works.row .col-2 {
        align-self: stretch;
      }
      .how-it-works.row .col-2::after {
        content: "";
        position: absolute;
        border-left: 3px solid #ED8D8D;
        z-index: 1;
      }
      .how-it-works.row .col-2.bottom::after {
        height: 50%;
        left: 50%;
        top: 50%;
      }
      .how-it-works.row .col-2.full::after {
        height: 100%;
        left: calc(50% - 3px);
      }
      .how-it-works.row .col-2.top::after {
        height: 50%;
        left: 50%;
        top: 0;
      }
      
      
      .timeline div {
        padding: 0;
        height: 40px;
      }
      .timeline hr {
        border-top: 3px solid #ED8D8D;
        margin: 0;
        top: 17px;
        position: relative;
      }
      .timeline .col-2 {
        display: flex;
        overflow: hidden;
      }
      .timeline .corner {
        border: 3px solid #ED8D8D;
        width: 100%;
        position: relative;
        border-radius: 15px;
      }
      .timeline .top-right {
        left: 50%;
        top: -50%;
      }
      .timeline .left-bottom {
        left: -50%;
        top: calc(50% - 3px);
      }
      .timeline .top-left {
        left: -50%;
        top: -50%;
      }
      .timeline .right-bottom {
        left: 50%;
        top: calc(50% - 3px);
      }
}