
$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";


.dc-logo{
width: 200px;
}

.dc-icon{
  height: 28px;
  padding: 0;
  margin: 0;
}


.parallax-container {
  height: 500px;
  width: 100%;
}

.parallax::after {
  /* Position the pseudo-element to fill the parent */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  /* Scale the image to full */
  background-size: cover;
  
  /* Move the element back then scale the element, it helps to appears more slowly */
  transform: translateZ(-1px) scale(1.7);
  
  /* Set the parallax section before the other elements. */
  z-index: -1;
}
// body {
//   background: $backgroundColor;
//   color: $bodyColor;
//   font-family: $bodyFont;
//   width: 100vw;
//   margin: 0;
//   padding: 0;
// }



// .containerVideo {
//   /* Center the content */
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   /* Take full size */
//   height: 100vh;
//   width: 100%;

//   /* Background */
//   background: url('/images/earth-1756274_1920.jpg') center center / cover no-repeat;
// }
.nav-background{
  background-color: black;
}


.paraheader {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 30vw 0 5vw;
  position: relative;
  transform-style: inherit;
  width: 100vw;
}

.paraheader h1 {
  margin-top: -100px;
}

.paraheader,
.paraheader::before {
  background: 50% 50% / cover;
}

.paraheader::before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  // background-image: url(https://images.wallpaperscraft.com/image/fog_trees_path_120732_1600x1200.jpg);
  background-size: cover;
  /*x y z */
  transform-origin: center center 0;
  transform: translateZ(-1px) scale(2);
  z-index: -1;
  min-height: 100vh;
}

.paraheader * {
  font-weight: normal;
  letter-spacing: 0.2em;
  text-align: center;
  margin: 0;
  padding: 1em 0;
}

.artSection{
  width: 100%;
}

.artcategory{
  height: 300px;
  color:black;
  background-color: red;
  background-position: center;
  background-size: cover;
  transition: 0.3s;

}

.artcategory:hover{
  background-image: none !important;
  background-color: black;
  color: white;
}
